.itemListCard {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.itemListCard:not(:first-child) {
  margin-top: 2rem;
}

.pinned {
  background-color: var(--ion-color-primary);
  color: white;
}

.cardTitle {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  color: white;
}

.cardTitle ion-icon {
  border-radius: 500px;
  padding: 0.2rem;
  margin-right: 0.75rem;
  background-color: var(--ion-color-primary);
  margin-top: -0.2rem;
}

.pinned .cardTitle ion-icon {
  background-color: white;
}

.itemListTitle {
  margin-top: -1rem;
}

.itemListTitle h3 {
  font-size: 1.3rem !important;
  font-weight: 600;
}

.itemListDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 0.5rem;
}

.itemListProducts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.productContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.audienceContainer {
  margin-bottom: 1rem;
  /* margin: 0.5rem; */
}

.itemListProduct {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 12px;
  margin-right: 0.2rem;
  background-color: var(--ion-color-primary);
}

.audienceContainer .itemListProduct {
  background-color: #f2efe5;
  border: 2px solid #dfd9c7;
}

.itemListProduct img {
  height: 3rem;
  width: 3rem;
}

.productContainer p {
  margin: 0;
  padding: 0;
  margin-top: 0.2rem;
  font-weight: 500;
  font-size: 0.9rem;
}

.itemListDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.detailCount {
  display: flex;
  align-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.detailCount ion-icon {
  font-size: 1.2rem;
  margin-right: 0.3rem;
  margin-left: -0.4rem;
}

.activeProduct {
  border: 3px solid rgb(255, 187, 0);
}
