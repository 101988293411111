.itemListCard {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  background-color: var(--theme-card-bg-color);
}

.itemListCard:not(:first-child) {
  margin-top: 2rem;
}

.pinned {
  background-color: var(--ion-color-primary);
  color: var(--theme-card-white);
}

.cardTitle {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  color: var(--theme-card-bg-color);
}

.cardTitle ion-icon {
  border-radius: 500px;
  padding: 0.2rem;
  margin-right: 0.75rem;
  background-color: var(--ion-color-primary);
  margin-top: -0.2rem;
}

.pinned .cardTitle ion-icon {
  background-color: var(--theme-card-white);
}

.pinned .itemListTitle h3 {
  color: var(--theme-card-white);
}

.itemListTitle h3 {
  font-size: 1.3rem !important;
  font-weight: 600;
  color: var(--theme-card-color);
}

.itemListDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 0.5rem;
}

.products {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
}

.product {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 12px;
  margin-right: 0.2rem;
  background-color: var(--ion-color-primary);
  cursor: pointer;
}

.product img {
  height: 2rem;
  width: 2rem;
}

.pinned .product {
  background-color: var(--theme-card-white);
}

.itemListDetails {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.pinned .detailCount {
  color: var(--theme-card-white);
}

.detailCount {
  display: flex;
  align-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 0.9rem;
  color: var(--theme-card-color);
}

.detailCount ion-icon {
  font-size: 1.2rem;
  margin-right: 0.3rem;
}

.tag {
  font-size: 0.75rem;
  border-color: var(--ion-color-primary);
  color: var(--ion-color-primary);
}

.pinned .tag {
  border-color: var(--theme-card-white);
  color: var(--theme-card-white);
}

.actions {
  margin-left: auto;
}
