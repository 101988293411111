.extra-padding {
  padding-right: 1.3rem !important;
  padding-left: 1.3rem !important;
}

.title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
