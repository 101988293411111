.field:not(:last-child) {
  margin-bottom: 1rem !important;
}

.field {
  ion-label {
    padding-left: 0.2rem;
    padding-right: 0.5rem;
    color: var(--ion-color-primary);
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
}
