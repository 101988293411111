:root {
  --ion-background-color: var(--theme-bg-color);
  --ion-tab-bar-color: var(--theme-card-bg-color);
  --ion-tab-bar-color-selected: var(--ion-color-primary);
  --ion-tab-bar-color: rgb(59, 59, 59);
}

ion-tab-bar {
  --background: var(--theme-card-bg-color);
  box-shadow: 0px 1px 13px var(--theme-card-shadow-color);
  border-radius: 50px !important;

  height: 50px;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;

  bottom: 20px;
  position: relative;
  margin: 0 auto !important;
  border-top: none;
}

ion-tab-button {
  border-radius: 16px !important;
}

ion-tab-button ion-icon {
  font-size: 1.75rem;
}
