.currency {
  padding: 0.8rem 0 0.8rem 0.8rem;
}
.price {
  font-weight: bold;
  font-size: 2rem;
}

.name {
  font-weight: bold;
}

.rating {
  padding-left: 0.5rem;
}
