$screen-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@each $size, $size-value in $screen-breakpoints {
  .hide-#{$size} {
    @media (max-width: $size-value) {
      display: none;
    }
  }
}

// header
.search {
  .searchbar-input {
    background-color: white;
    box-shadow: none;
    border-radius: 10px;
  }
}

ion-thumbnail.logo {
  --size: 38px;
}

// form
.field-input {
  --background: var(--theme-card-white);
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 10px;
  margin-top: 0.25rem;
  transition: all 0.2s linear;
}
